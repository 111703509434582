import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Label,
  Button,
  Input
} from "reactstrap";
import {base,s3base} from "api.js";
import { Checkbox } from '@material-ui/core';
import Sound from 'react-sound';
import "components/custom.css";

class McqMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      selectedValue:[],
      soundPer:0,
      volume:100,
      correctFlag:true,
      resultObj:{}
    };
  }
  componentDidMount() {
  	var intervalId = setInterval(this.timer, 1000);
	this.setState({intervalId: intervalId});
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			return {correctFlag:true,recordCount:0,soundPer:0,isRecording:false,secondCount:10,questionId: props.data.id,blobURL:'',reset:true,soundStatus:true};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  		
  		} 
  }
  
  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
			
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();
    this.props.showResult();
  } 

  removeItem = (array, item) => {
    for(var i in array){
        if(array[i]==item){
            array.splice(i,1);
            break;
        }
    }
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

  handleChange = (event) => {
    this.props.showResult();
    let correct = this.props.data.option.filter(function (item) {
      return item.correct == 1;
    }).map(function (i) {
      return i.id;
    })
        let arr = this.state.selectedValue;
        let val = event.target.value;
        arr.includes(parseInt(val))?this.removeItem(arr, parseInt(val)):arr.push(parseInt(val))
        this.props.submit({'answer':arr,'correct':correct},true);
        this.setState({selectedValue:arr, resultObj:{'answer':arr,'correct':correct} });
  };

  updateCorrect = () =>{
    let correct = this.props.data.option.filter(function (item) {
      return item.correct == 1;
    }).map(function (i) {
      return i.id;
    })
    this.props.submit({'answer':[],'correct':correct});
    this.setState({correctFlag:false, resultObj: {'answer':[],'correct':correct} });
  }
  getFormattedMediaLink = (mediaLink) => {
        if (!mediaLink) return '';
          return s3base + (mediaLink.startsWith('/') ? mediaLink : `/${mediaLink}`);
      };
    
  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  
  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
  		question = this.props.data.question_mcq;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		this.setState({intervalId: intervalId,reset:false,selectedValue:[]});
  	}
    return (
      <>
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      <div className="container-fluid">
        <div className="row">
          { !this.props.resultMode && <Col xs={12}>
            <div className="col-lg-12 p-0 text-center">
              <div class="currentStatus">
                <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
                {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }
                
                <br></br>
                Volume
                 <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
                 <br></br>
                <Progress color="info" value={(this.state.soundPer)} />
                {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }
                </p>
                
              </div>
              
              <Col xs={12}>
                {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 && <Sound
                  url={this.getFormattedMediaLink(this.props.data.media_link)}
                  playStatus={Sound.status.PLAYING}
                  onFinishedPlaying={this.handleSongFinishedPlaying}
                  onPlaying={this.handleSongPlaying}
                  volume={this.state.volume}          
                />}
              </Col>
              <Col xs={12}>
                { this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
              </Col>
            </div>
          </Col> }
          <Col xs={12}><h3 className="question">{question}</h3>
            <div className="answers">
            {this.props.data && this.props.resultMode && this.props.data.option.map(function(item){
                    return <React.Fragment>
                            <Label className={ this.props.answer && item.correct === 1 && 'correct-line' }>
                                  <Checkbox
                                      onChange={this.handleChange}
                                      checked={this.state.selectedValue.includes(item.id)}
                                      value={item.id}
                                      color="default"
                                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      name="answer"
                                    />{' '}
                                  {item.options}
                                </Label>
                          </React.Fragment>;
                  },this) }
            {this.props.data && !this.props.resultMode && this.props.data.option.map(function(item){
                return <React.Fragment>
                        <Label className={ this.props.answer && (item.correct === 1?(this.state.selectedValue.includes(item.id)?'correct-line':'blue-line'):(this.state.selectedValue.includes(item.id))?'red-line':'') }>
                          <Checkbox
                              checked={this.state.selectedValue.includes(item.id)}
                              value={item.id}
                              color="default"
                              onChange={this.handleChange}
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              name="answer"
                            />{' '}
                          {item.options}
                        </Label>
                      </React.Fragment>;
              },this) }
            </div>
          </Col>
        </div>
      </div>
      </>
    );
  }
}
export default McqMulti;