import React, { Component } from "react";
import {
  Col,
  Progress,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { confirmAlert } from 'react-confirm-alert';
// import Dictaphone from "./Dictaphone.jsx";
import NewDictaphone from "./NewDictaphone.jsx";
import { base, s3base } from "api.js";
import NoMicrophone from "views/NoMicrophone.jsx";
import Sound from "react-sound";
import getBlobDuration from "get-blob-duration";
import MicRecorder from "mic-recorder-to-mp3";
import "components/custom.css";
let Mp3Recorder = new MicRecorder({ bitRate: 128 });
class RepeatSentece extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      question: "",
      secondCount: 3,
      secondCount2: 0,
      recordCount: 0,
      recordId: 1,
      intervalId: 0,
      intervalId2: 0,
      questionId: "",
      reset: false,
      soundStatus: true,
      soundPer: 0,
      volume: 100,
      trans: "",
      correctFlag: true,
      beepStatus: false,
      file: "",
      duration: 0,
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
      var intervalId = setInterval(this.timer, 1000);
      this.setState({ intervalId: intervalId });
    }
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        this.setState({ isBlocked: false });
      },
      () => {
        console.log("Permission Denied");
        this.setState({ isBlocked: true });
      }
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if (
        (state.questionId && state.questionId !== props.data.id) ||
        props.restart
      ) {
        clearInterval(state.intervalId);
        clearInterval(state.intervalId2);
        clearInterval(state.recordId);
        return {
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          isRecording: false,
          secondCount: 3,
          secondCount2: 0,
          questionId: props.data.id,
          blobURL: "",
          trans: "",
          duration: 0,
          reset: true,
          soundStatus: true,
          intervalId2: 0,
        };
      } else {
        if (!state.questionId) {
          return { correctFlag: true, questionId: props.data.id };
        }
      }
    }
  }

  start = () => {
    //this.setState({ beepStatus:true});
    SpeechRecognition.startListening({ continuous: true, language: "en-GB" });
    Mp3Recorder = new MicRecorder({ bitRate: 128 });
    Mp3Recorder.start()
      .then(() => {
        this.setState({ isRecording: true, blobURL: "", beepStatus: false });
      })
      .catch((e) => console.error(e));
  };

  handleBeepFinishedPlaying = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      /*Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));*/
    }
  };

  // toggleModal = () => {
  //   this.setState((prevState) => ({
  //     modalOpen: !prevState.modalOpen,
  //   }));
  // };

  getDuration = async (blob) => {
    let duration = 0;
    duration = await getBlobDuration(blob)
      .then((d) => {
        return d;
      })
      .catch(async (e) => {
        return await getBlobDuration(URL.createObjectURL(blob));
      });
    return duration;
  };

  getFile = (blob, buffer) => {
    let file = null;
    try {
      file = new File([blob], "noname3.mp3", { type: blob.type });
    } catch (err) {
      file = new File(buffer, "noname3.mp3", {
        type: blob.type,
        lastModified: Date.now(),
      });
    }
    return file;
  };

  getBlobURL = (blob, file) => {
    let url = null;
    try {
      url = URL.createObjectURL(file);
    } catch (err) {
      url = URL.createObjectURL(blob);
    }
    return url;
  };
// deleteComment = () =>{
//     console.log('<<<<<<<','Alert')
//     confirmAlert({
//       title: '',
//       message: 'Sorry!! We were unable to detect your voice !',
//       buttons: [
//         {
//           label: 'Would you like to retry?',
//           onClick: () => {this.props.restartQuestion()}
//         },
//         {
//           label: 'Continue',
//           onClick: () => {this.stop()}
//         }
//       ]
//     });
//   }
  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      SpeechRecognition.stopListening();

      setTimeout(() => {
        Mp3Recorder.stop()
          .getMp3()
          .then(async ([buffer, blob]) => {
            const duration = await this.getDuration(blob);
            const file = this.getFile(blob, buffer);
            const blobURL = this.getBlobURL(blob, file);

            this.setState(
              {
                blobURL,
                isRecording: false,
                recordCount: 100,
                file: file,
                duration: duration,
              },
              () => {
                const dataToSend = {
                  data: file,
                  audio_text: this.state.trans,
                  duration: duration,
                };
                this.props.submit(dataToSend, true);
                this.props.showResult();
              }
            );
          })
          .catch((e) => console.log(e));
      }, 100);
    }


    /**if (this.state.recordCount > 3) {
      // Check if transcription has any value
      if (!this.state.trans) {
        //this.toggleModal(); // Open the modal
        //this.deleteComment();
        return;
      }
    }*/

  };

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      SpeechRecognition.stopListening();

      setTimeout(() => {
        Mp3Recorder.stop()
          .getMp3()
          .then(async ([buffer, blob]) => {
            const duration = await this.getDuration(blob);
            const file = this.getFile(blob, buffer);
            const blobURL = this.getBlobURL(blob, file);

            this.props.nextQuestion({
              data: file,
              audio_text: this.state.trans,
              duration: duration,
            });
          })
          .catch((e) => console.log(e));
      }, 100);
    } else {
      this.props.nextQuestion({
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration,
      });
    }
  };

  updateCorrect = () => {
    this.props.submit({ data: "", duration: this.state.duration });
    this.setState({ correctFlag: false });
  };

  setTrans = (a) => {
    if (this.state.trans != a) {
      this.setState({ trans: a });
    }
  };

  timer = () => {
    if (this.state.secondCount === 0) {
      clearInterval(this.state.intervalId);
    } else {
      this.setState({ secondCount: this.state.secondCount - 1 });
    }
  };

  timer1 = () => {
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId2);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({ soundStatus: false, recordId: recordId });
    } else {
      this.setState({ secondCount2: this.state.secondCount2 - 1 });
    }
  };

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({ secondCount: 0 });
  };

  handleSongFinishedPlaying = () => {
    //var intervalId = setInterval(this.timer1, 1000);
    //this.setState({'soundStatus':false})
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      this.setState({ soundStatus: false });
      this.props.soundChecked();
      /* Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));*/
    }
    //this.start();
    //var recordId = setInterval(this.timerRecord, 1000);
    //this.setState({'soundStatus':false,recordId: recordId});
  };

  handleSongPlaying = (obj) => {
    this.setState({ soundPer: (obj.position * 100) / obj.duration });
  };

  loadingSound = (obj) => {
    let d = obj.duration / 1000 + 1;
    if (obj.duration !== 0 && this.state.intervalId2 === 0) {
      let intervalId = setInterval(this.timer1, 1000);
      this.setState({
        soundStatus: true,
        intervalId2: intervalId,
        secondCount2: Math.ceil(d),
      });
    }
  };

  timerRecord = () => {
    if (this.state.recordCount === 8) {
      clearInterval(this.state.recordId);
      this.stop();
    }
    this.setState({ recordCount: this.state.recordCount + 1 });
  };

  changeRange = (e) => {
    this.setState({ volume: e.target.value });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };

  updateTranscript = (transcript) => {
    this.setState({
      trans: transcript
    });
  };

  render() {
    let question = "";

    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();
    }

    if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
      question = this.props.data.question;
    }

    if (this.state.reset) {
      var intervalId = setInterval(this.timer, 1000);
      this.setState({ intervalId: intervalId, reset: false });
    }

    if (this.state.isBlocked) {
      return <NoMicrophone />;
    }

    return (
      <>
        {/*<Dictaphone
          setTrans={this.setTrans}
          status={this.state.reset || this.state.isRecording}
        />*/}

        <NewDictaphone
          updateTranscript={this.updateTranscript}
        />

        <Col xs={12}>
          <h4 className="infoText"> </h4>
        </Col>

        <Col xs={12}>
          <div className="col-lg-12 p-0 text-center">
            {!this.props.resultMode && (
              <div>
                <div className="currentStatus">
                  <div>
                    Current Status:
                    {this.state.soundStatus == true &&
                      this.state.secondCount !== 0 &&
                      !this.state.isRecording &&
                      " Begining in " + this.state.secondCount + " seconds"}
                    {this.state.secondCount == 0 &&
                      this.state.blobURL === "" &&
                      !this.state.isRecording &&
                      " Playing"}
                    <br></br>
                    Volume
                    <Input
                      placeholder="Volume"
                      type="range"
                      min="0"
                      defaultValue={this.state.volume}
                      step="1"
                      onInput={(e) => this.changeRange(e)}
                      max="100"
                    />
                    <br></br>
                    <Progress color="info" value={this.state.soundPer} />
                    {!this.props.testMode &&
                      this.state.secondCount !== 0 &&
                      this.state.blobURL === "" &&
                      !this.state.isRecording && (
                        <div className="stop-btn">
                          <Button color="success" onClick={this.skip} size="sm">
                            Skip
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
                <div className="audio-box">
                  <h4>Recorded Answer</h4>
                  <p>
                    <strong>Current status:</strong>
                  </p>
                  <p>
                    {" "}
                    {this.state.blobURL === "" && this.state.isRecording
                      ? "Recording started"
                      : ""}{" "}
                  </p>
                  <p>
                    {" "}
                    {this.state.secondCount == 0 &&
                      this.state.secondCount2 !== 0 &&
                      "Recording starts in " +
                        this.state.secondCount2 +
                        " seconds"}
                  </p>

                  <hr></hr>
                  {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
                  {!this.props.testMode && this.state.isRecording && (
                    <div className="stop-btn">
                      <Button color="success" onClick={this.stop} size="sm">
                        Stop
                      </Button>
                    </div>
                  )}
                  <hr></hr>
                  {
                    // eslint-disable-next-line max-len
                  }
                  <Progress
                    color="info"
                    value={this.state.recordCount * 12.5}
                  />
                  <div className="audo-line">
                    <span className="progress-bar-fill-Read intro"></span>
                  </div>
                </div>
              </div>
            )}

            <Col xs={12}>
              {!this.props.resultMode &&
                this.props.data &&
                this.state.soundStatus &&
                this.state.secondCount === 0 && (
                  <Sound
                   url={
                      this.props.data.media_link.startsWith('/')
                        ? s3base + this.props.data.media_link
                        : s3base + `/${this.props.data.media_link}`
                    }
                    
                    playStatus={Sound.status.PLAYING}
                    onPlaying={this.handleSongPlaying}
                    volume={this.state.volume}
                    onLoading={this.loadingSound}
                    onFinishedPlaying={this.handleSongFinishedPlaying}
                  />
                )}
            </Col>

            <Col xs={12}>
              {this.state.blobURL && !this.props.testMode && (
                <audio src={this.state.blobURL} controls="controls" />
              )}
            </Col>
          </div>
        </Col>

        <Col xs={12}>
          <h5>{question}</h5>
        </Col>

        {!this.props.resultMode && this.state.beepStatus && (
          <Sound
            url={s3base + "/ptedata/ptemedia/beep.mp3"}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleBeepFinishedPlaying}
          />
        )}
        {/* <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Sorry Recording Error
          </ModalHeader>
          <ModalBody>
            <p>Sorry!! We were unable to detect your voice !</p>
          </ModalBody>

          <ModalFooter>
            <div className="d-flex justify-content-between w-100">
              <Button
                color="secondary"
                size="sm"
                onClick={() => {
                  this.props.restartQuestion();
                  this.toggleModal();
                }}
              >
                Would you like to retry?
              </Button>
              <Button color="primary" size="sm" onClick={this.stop}>
                Continue
              </Button>
            </div>
          </ModalFooter>
        </Modal> */}
      </>
    );
  }
}
export default RepeatSentece;
