import React, { useState, useEffect } from 'react';
import {
	Input,
	Progress,
	Button
} from 'reactstrap';
import Sound from 'react-sound';
import { s3base } from 'api.js';

export default function QuestionAudio({
	updateQuestionAudioStatus,
	questionAudio,
	playQuestionAudio,
	updateWaitingStatus,
	updateRecordingStartsIn
}) {

	const [volume, setVolume] = useState(100);
	const [progress, setProgress] = useState(0);
	const [showSkip, setShowSkip] = useState(true);
	const [showQuestionSkip, setShowQuestionSkip] = useState(false);
	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED)
	const [formattedAudioUrl, setFormattedAudioUrl] = useState('');

	useEffect(() => {
		console.log("Updated questionAudio:", questionAudio);
		if (questionAudio) {
			const formattedUrl = questionAudio.startsWith('/')
				? questionAudio
				: `/${questionAudio}`;
			setFormattedAudioUrl(formattedUrl);
		}
	}, []);
	
	useEffect(() => {
		if (playQuestionAudio) {
			setPlayStatus(Sound.status.PLAYING);
			setShowSkip(false);
			setShowQuestionSkip(true);
		} else if (!showSkip && !playQuestionAudio) {
			setShowQuestionSkip(false);
		}
	}, [playQuestionAudio]);

	const changeRange = (e) => {
		let { value } = e.target;
		value = ((value > 100) ? 100 : (value < 0 ? 0 : value));
		setVolume(parseInt(value));
	}

	const audioLoaded = (obj) => {
		const d = obj.duration / 1000 + 9;
		if (obj.duration > 0) {
			updateRecordingStartsIn(Math.ceil(d));
		}
	}

	const onPlaying = (obj) => {
		setProgress((obj.position * 100) / obj.duration);
	}

	const playbackFinished = () => {
		setPlayStatus(Sound.status.STOPPED);
		updateQuestionAudioStatus(false);
	}

	const skipWait = () => {
		updateWaitingStatus(false);
	}

	const skipQuestion = () => {
		setPlayStatus(Sound.status.STOPPED);
		updateQuestionAudioStatus(false);
		updateRecordingStartsIn(10);
	}

	return (
		<>
			<Sound
				url={`${s3base}${formattedAudioUrl}`}
				playStatus={playStatus}
				onLoad={audioLoaded}
				onPlaying={onPlaying}
				onFinishedPlaying={playbackFinished}
				volume={volume}
				autoLoad={true}
			/>
			
			Volume
			<Input
				placeholder="Volume"
				type="range"
				min="0"
				defaultValue={volume}
				step="1"
				onInput={changeRange}
				max="100"
			/>
			<br></br>
			<Progress
				color="info"
				value={progress}
			/>
			<div className="stop-btn">
				{showSkip &&
					<Button color="success" onClick={skipWait} size="sm">
						Skip
					</Button>
				}
				{showQuestionSkip &&
					<Button color="success" onClick={skipQuestion} size="sm">
						Skip
					</Button>
				}
			</div>
		</>
	);
}